// context/AllProductContext.js
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const AllProductContext = createContext();

const baseUrl = 'https://ecom-f6hf.onrender.com';

export const AllProductContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const [allProduct, setAllProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log("dasdasdas");
        const response = await axios.get(`${baseUrl}/product/showyes`);
        setAllProduct(response.data.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
        console.error("Error fetching products:");
        navigate("/error");
      }
    };

    fetchProducts();
  }, []);

  return (
    <AllProductContext.Provider value={{ allProduct, isLoading, error }}>
      {children}
    </AllProductContext.Provider>
  );
};
