import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const baseUrl = `https://ecom-f6hf.onrender.com`;

// Create context
export const LikeContext = createContext();

// CartContextProvider
export const LikeContextProvider = ({ children }) => {
    const userId = localStorage.getItem('PcUserId');
    const navigate = useNavigate();

    const [likeList, setLikeList] = useState([]);
    const [likeId, setLikeId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchLikeList = async () => {
        if (userId !== null) {
            try {
                const response = await axios.get(`${baseUrl}/review/showlike/${userId}`);
                setLikeId(response.data.data[0]._id)
                setLikeList(response.data.data[0].product_list);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                console.error("Error fetching like list:");
                navigate("/error");
            }
        }

    };


    const refreshLikeList = async () => {
        setIsLoading(true);
        await fetchLikeList();
    };


    useEffect(() => {
        if (userId !== null) {
            fetchLikeList();
        }
    }, [userId]);

    return (
        <LikeContext.Provider value={{ likeList, likeId, isLoading, error, refreshLikeList }}>
            {children}
        </LikeContext.Provider>
    );
};

