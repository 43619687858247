import { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LikeContext } from "../context/likeContext";
import LikeDetails from "../components/like/like-details";

const baseUrl = `https://ecom-f6hf.onrender.com`;

const Like = () => {
    const { likeList } = useContext(LikeContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className="cart-items">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8}>
                        {likeList.length === 0 && (
                            <h1 className="no-items product">No Items in Like List</h1>
                        )}
                        {likeList.map((item) => (
                            <LikeDetails selectedProduct={item} />
                        ))}
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Like;
