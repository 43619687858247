import React, { useState } from 'react';
import './auth.css'; // Import your CSS file
import { MdCall } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const baseUrl = `https://ecom-f6hf.onrender.com`;

const RefPage = () => {
    const navigate = useNavigate();
    const userId = localStorage.getItem('PcUserId');

    const [refinfo, setRefInfo] = useState('');
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true); // Start loading animation

        try {
            const response = await axios.post(`${baseUrl}/coupne/courefpne`, {
                refmobile: refinfo,
                useId: userId,
            });

            if (response.data.status === "ref not availble") {
                toast.error("User not avalible, try another one or skip");
            } else {
                navigate("/");
                toast.success("Welcome to Pc Computer");
            }

        } catch (error) {
            console.error("Error fetching ads:");
            navigate("/error");
        } finally {
            setLoading(false); // Stop loading animation
        }
    };

    return (
        <div className="wrapper">
            <p className="account-signup">
                <h2 className="form-signin-heading">Referrance</h2>
            </p>
            <form className="form-signin" onSubmit={handleSubmit}>
                <div className="inner-addon left-addon">
                    <label className="control-label">Enter the referral person mobile no</label>
                    <i className="glyphicon">
                        <MdCall />
                    </i>
                    <input
                        type="text"
                        className="form-control"
                        name="site"
                        placeholder="1234567890"
                        required
                        autoFocus
                        value={refinfo}
                        onChange={(e) => setRefInfo(e.target.value)}
                    />
                </div>
                <a className="forgot-password" href="/">
                    Not have referrance, Skip
                </a>
                <button className="btn btn-lg btn-primary btn-block authbutton" type="submit" disabled={isLoading}>
                    {isLoading ? 'In Process...' : 'Submit'}
                </button>
            </form>
        </div>
    );
};

export default RefPage;
