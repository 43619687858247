import React, { useState } from 'react';
import './auth.css'; // Import your CSS file
import { MdEmail, MdLock, MdCall } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const baseUrl = `https://ecom-f6hf.onrender.com`;


// Reusable InputField component
const InputField = ({ label, icon: Icon, type, name, placeholder, value, onChange, error }) => (
    <div className="inner-addon left-addon">
        <label className="control-label">{label}</label>
        <i className="glyphicon">
            <Icon />
        </i>
        <input
            type={type}
            className={`form-control ${error ? 'is-invalid' : ''}`}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
        {error && <div className="invalid-feedback">{error}</div>}
    </div>
);

const SignForm = () => {
    const navigate = useNavigate();

    // State variables for form inputs and errors
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        password: ''
    });

    const [errors, setErrors] = useState({
        email: '',
        mobile: '',
        password: ''
    });

    const [isLoading, setLoading] = useState(false);

    // Regular expressions for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        // Clear error message on input change
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    // Form validation function
    const validateForm = () => {
        let valid = true;

        // Validate email
        if (!emailRegex.test(formData.email)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                email: 'Enter a valid email address'
            }));
            valid = false;
        }

        // Validate mobile number
        if (!mobileRegex.test(formData.mobile)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                mobile: 'Enter a valid 10-digit mobile number'
            }));
            valid = false;
        }

        // Validate password (simple example, adjust as per your requirements)
        if (formData.password.length < 6) {
            setErrors(prevErrors => ({
                ...prevErrors,
                password: 'Password must be at least 6 characters long'
            }));
            valid = false;
        }

        return valid;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form
        if (!validateForm()) {
            return;
        }


        setLoading(true); // Start loading animation

        try {
            const response = await axios.post(`${baseUrl}/verify`, {
                email: formData.email,
                password: formData.password,
                name: formData.name,
                mobile_numer: formData.mobile,
                profile_photo: "",
                lat: "",
                lon: "",
            });

            toast.info("Check your register mail for otp");
            localStorage.setItem("tempPcName", formData.name);
            localStorage.setItem("tempPcMobile", formData.mobile);
            localStorage.setItem("tempPcEmail", formData.email);
            localStorage.setItem("tempPcPassword", formData.password);
            localStorage.setItem("tempPcOTP", response.data.otp);
            navigate("/signInOtp");

        } catch (error) {
            if (error.message === "Request failed with status code 409") {
                toast.error("email or mobile number already registered");
                navigate("/login");
            } else {
                toast.error("Something wrong, Please try again");
                setFormData({
                    name: '',
                    mobile: '',
                    email: '',
                    password: ''
                })
            }
        } finally {
            setLoading(false); // Stop loading animation
        }
    };

    return (
        <div className="wrapper">
            <p className="account-signup">
                <h2 className="form-signin-heading">Sign Up</h2>
            </p>
            <form className="form-signin" onSubmit={handleSubmit}>
                <InputField
                    label="Name"
                    icon={FaUser}
                    type="text"
                    name="name"
                    placeholder="abc"
                    value={formData.name}
                    onChange={handleInputChange}
                    error={errors.name}
                />
                <InputField
                    label="Mobile No"
                    icon={MdCall}
                    type="text"
                    name="mobile"
                    placeholder="1234567890"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    error={errors.mobile}
                />
                <InputField
                    label="Email Id"
                    icon={MdEmail}
                    type="text"
                    name="email"
                    placeholder="abc@gmail.com"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={errors.email}
                />
                <InputField
                    label="Password"
                    icon={MdLock}
                    type="password"
                    name="password"
                    placeholder="*******"
                    value={formData.password}
                    onChange={handleInputChange}
                    error={errors.password}
                />
                <button className="btn btn-lg btn-primary btn-block authbutton" type="submit" disabled={isLoading}>
                    {isLoading ? 'Signing Up...' : 'Sign Up'}
                </button>
            </form>
            <p className="account-signup">
                Already have an account? <a href="/login" style={{ color: "white" }}>Login here</a>
            </p>
        </div>
    );
};

export default SignForm;
