// App.js
import { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AllProductContextProvider } from "./context/AllProductContext";
import { AdContextProvider } from "./context/AdsContext";
import { BrandContextProvider } from "./context/brandContext";
import { CategoryContextProvider } from "./context/CategoryContect";
import SignForm from "./pages/auth/sign";
import SignInOtp from "./pages/auth/sign_otp";
import RefPage from "./pages/auth/ref";
import ForgotPass from "./pages/auth/forgot_pass/enter_email";
import ForgotOtp from "./pages/auth/forgot_pass/otp_verify";
import ForgotNewPass from "./pages/auth/forgot_pass/new_pass";
import PrivacyPolicy from "./pages/policy/privacy_policy";
import ReturnPolicy from "./pages/policy/return_policy";
import TermsOfService from "./pages/policy/terms_of_service";
import Error from "./pages/error/error";
import { CartContextProvider } from "./context/cartContext";
import Like from "./pages/like";
import { LikeContextProvider } from "./context/likeContext";
import { BranchContextProvider } from "./context/branchContext";
import { InvoiceContextProvider } from "./context/invoiceContext";
import { AddressContextProvider } from "./context/addressContext";
import Profile from "./pages/profile";
import { ProfileContextProvider } from "./context/profileContext";
import { MyOrderContextProvider } from "./context/myOrderContext";
import Branch from "./pages/branch";

const Login = lazy(() => import("./pages/auth/login"));
const Home = lazy(() => import("./pages/Home"));
// const Shop = lazy(() => import("./pages/Shop"));
const Cart = lazy(() => import("./pages/Cart"));
const Product = lazy(() => import("./pages/Product"));

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <ProfileContextProvider>
          <AddressContextProvider>
            <InvoiceContextProvider>
              <CartContextProvider>
                <LikeContextProvider>
                  <BranchContextProvider>
                    <NavBar />
                    <AllProductContextProvider>
                      <AdContextProvider>
                        <BrandContextProvider>
                          <CategoryContextProvider>
                            <MyOrderContextProvider>
                              <Routes>
                                <Route path="/" exact={true} element={<Home />} />
                                <Route path="/login" exact={true} element={<Login />} />
                                <Route path="/sign" exact={true} element={<SignForm />} />
                                <Route path="/signInOtp" exact={true} element={<SignInOtp />} />
                                <Route path="/referral" exact={true} element={<RefPage />} />
                                <Route path="/forgotpass" exact={true} element={<ForgotPass />} />
                                <Route path="/forgototp" exact={true} element={<ForgotOtp />} />
                                <Route path="/forgotnewpassword" exact={true} element={<ForgotNewPass />} />
                                {/* <Route path="/shop" exact={true} element={<Shop />} /> */}
                                <Route path="/shop/:id" exact={true} element={<Product />} />
                                <Route path="/cart" exact={true} element={<Cart />} />
                                <Route path="/branch" exact={true} element={<Branch />} />
                                <Route path="/like" exact={true} element={<Like />} />
                                <Route path="/profile" exact={true} element={<Profile />} />
                                <Route path="/privacypolicy" exact={true} element={<PrivacyPolicy />} />
                                <Route path="/returnpolicy" exact={true} element={<ReturnPolicy />} />
                                <Route path="/termsofservice" exact={true} element={<TermsOfService />} />
                                <Route path="*" element={<Error />} />
                              </Routes>
                            </MyOrderContextProvider>
                          </CategoryContextProvider>
                        </BrandContextProvider>
                      </AdContextProvider>
                    </AllProductContextProvider>
                    <Footer />
                  </BranchContextProvider>
                </LikeContextProvider>
              </CartContextProvider>
            </InvoiceContextProvider>
          </AddressContextProvider>
        </ProfileContextProvider>
      </Router>
    </Suspense>
  );
}

export default App;