import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const baseUrl = `https://ecom-f6hf.onrender.com`;

// Create context
export const myOrderContext = createContext();

// Context provider component
export const MyOrderContextProvider = ({ children }) => {
    const userId = localStorage.getItem('PcUserId');

    const navigate = useNavigate();

    const [myOrderData, setMyOrderData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMyOrderData = async () => {
            if (userId !== null) {
                try {
                    // Check if ads are already fetched
                    if (myOrderData.length === 0) {
                        console.log("321313131313");
                        const response = await axios.get(`${baseUrl}/order/ordershow/${userId}`);
                        setMyOrderData(response.data.data);
                    }
                    setIsLoading(false);
                } catch (error) {
                    setError(error);
                    console.error("Error fetching ads:");
                    navigate("/error");
                }
            }

        };
        fetchMyOrderData();
    }, [myOrderData, navigate]);

    return (
        <myOrderContext.Provider value={{ myOrderData, isLoading, error }}>
            {children}
        </myOrderContext.Provider>
    );
};
