import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const baseUrl = `https://ecom-f6hf.onrender.com`;

// Create context
export const CartContext = createContext();

// CartContextProvider
export const CartContextProvider = ({ children }) => {
    const userId = localStorage.getItem('PcUserId');
    const navigate = useNavigate();

    const [cartList, setCartList] = useState([]);
    const [cartId, setCartId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCartList = async () => {
        if (userId !== null) {
            try {
                const response = await axios.get(`${baseUrl}/order/showcart/${userId}`);
                setCartId(response.data.data[0]._id)
                setCartList(response.data.data[0].product_list);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                console.error("Error fetching cart list:");
                navigate("/error");
            }
        }

    };


    const refreshCartList = async () => {
        setIsLoading(true);
        await fetchCartList();
    };


    useEffect(() => {
        if (userId !== null) {
            fetchCartList();
        }
    }, [userId]);

    return (
        <CartContext.Provider value={{ cartList, cartId, isLoading, error, refreshCartList }}>
            {children}
        </CartContext.Provider>
    );
};

