import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const baseUrl = `https://ecom-f6hf.onrender.com`;

// Create context
export const profileContext = createContext();

// Context provider component
export const ProfileContextProvider = ({ children }) => {
    const userId = localStorage.getItem('PcUserId');

    const navigate = useNavigate();

    const [profileData, setProfileData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProfileData = async () => {
            if (userId !== null) {
                try {
                    // Check if ads are already fetched
                    if (profileData.length === 0) {
                        console.log("321313131313");
                        const response = await axios.get(`${baseUrl}/finduser/${userId}`);
                        setProfileData(response.data.data);
                    }
                    setIsLoading(false);
                } catch (error) {
                    setError(error);
                    console.error("Error fetching ads:");
                    navigate("/error");
                }
            }

        };
        fetchProfileData();
    }, [profileData, navigate]);

    return (
        <profileContext.Provider value={{ profileData, isLoading, error }}>
            {children}
        </profileContext.Provider>
    );
};
