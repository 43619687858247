import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const baseUrl = `https://ecom-f6hf.onrender.com`;

// Create context
export const AdContext = createContext();

// Context provider component
export const AdContextProvider = ({ children }) => {
    const navigate = useNavigate();

    const [ads, setAds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAds = async () => {
            try {
                // Check if ads are already fetched
                if (ads.length === 0) {
                    console.log("321313131313");
                    const response = await axios.get(`${baseUrl}/ads/showall`);
                    setAds(response.data.data);
                }
                setIsLoading(false);
            } catch (error) {
                setError(error);
                console.error("Error fetching ads:");
                navigate("/error");
            }
        };
        fetchAds();
    }, []);

    return (
        <AdContext.Provider value={{ ads, isLoading, error }}>
            {children}
        </AdContext.Provider>
    );
};
