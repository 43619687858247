import { useState, useEffect, useContext } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiOutlineShoppingCart, AiOutlineHeart, AiOutlineUser } from 'react-icons/ai';
import { IoLogOutOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { CartContext } from "../../context/cartContext";
import { LikeContext } from "../../context/likeContext";



const NavBar = () => {

  const login = localStorage.getItem('PcUserRole');

  const { cartList } = useContext(CartContext);
  const { likeList } = useContext(LikeContext);



  // const { cartList } = useSelector((state) => state.cart); // Redux state for cart items
  // const likeList = useSelector((state) => state.likes); // Example: Redux state for liked items

  const [expand, setExpand] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  // fixed Header
  function scrollHandler() {
    if (window.scrollY >= 100) {
      setIsFixed(true);
    } else if (window.scrollY <= 50) {
      setIsFixed(false);
    }
  }

  // Add scroll listener on component mount
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <Navbar fixed="top" expand="md" className={isFixed ? "navbar fixed" : "navbar"}>
      <Container className="navbar-container">
        <Navbar.Brand to="/">
          <Nav.Item>
            <Link
              aria-label="Go to Home Page"
              className="navbar-link"
              to="/"
            >
              <h1 className="logo">PC Computer</h1>
            </Link>
          </Nav.Item>

        </Navbar.Brand>
        {/* Media cart and toggle */}
        <div className="d-flex">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => {
              setExpand(expand ? false : "expanded");
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">

            <Nav.Item>
              <Link
                aria-label="Go to Shop Page"
                className="navbar-link"
                to={login === null ? "/login" : "/like"}
                onClick={() => setExpand(false)}
              >
                <AiOutlineHeart className="nav-icon" />
                <span className="nav-link-label">Like</span>
                {likeList.length > 0 && (
                  <span className="item-count">{likeList.length}</span>
                )}
              </Link>
            </Nav.Item>

            <Nav.Item>
              <Link
                aria-label="Go to Cart Page"
                className="navbar-link"
                to={login === null ? "/login" : "/cart"}
                onClick={() => setExpand(false)}
              >
                <AiOutlineShoppingCart className="nav-icon" />
                <span className="nav-link-label">Cart</span>
                {cartList.length > 0 && (
                  <span className="item-count">{cartList.length}</span>
                )}
              </Link>
            </Nav.Item>

            <Nav.Item>
              <Link
                aria-label="Go to Profile Page"
                className="navbar-link"
                to={login === null ? "/login" : "/profile"}
                onClick={() => setExpand(false)}
              >
                <AiOutlineUser className="nav-icon" />
                <span className="nav-link-label">Profile</span>
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar >
  );
};

export default NavBar;