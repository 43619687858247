import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../context/cartContext";
import axios from "axios";
import { LikeContext } from "../../context/likeContext";
import { MdDelete } from "react-icons/md";

const baseUrl = `https://ecom-f6hf.onrender.com`;

const LikeDetails = ({ selectedProduct }) => {

    const navigate = useNavigate();

    const { likeId, refreshLikeList } = useContext(LikeContext);
    const { cartList, refreshCartList, cartId } = useContext(CartContext);
    const [quantity, setQuantity] = useState(0);
    const login = localStorage.getItem('PcUserRole');
    const userId = localStorage.getItem('PcUserId');


    useEffect(() => {
        const isProductExists = cartList.find(item => item.product_id._id === selectedProduct.product_id._id)

        if (isProductExists) {
            setQuantity(isProductExists.quantity)
        } else {
            setQuantity(0)
        }
    });

    const handleAddToCart = async (id) => {
        if (!login) {
            toast.info("Please Sign in before add product into cart")
            navigate("/Login");
        } else {
            if (quantity > 0) {
                toast.info("Item already in cart list");
            } else {
                try {
                    const response = await axios.post(`${baseUrl}/order/addtocart/${userId}`, {
                        product_id: id
                    });
                    if (response.data.status === "add to cart") {
                        toast.success("Product added to cart");
                        refreshCartList();
                    }

                } catch (error) {
                    console.error("Error fetching ads");
                }
            }
        }
    };

    const updateQuantity = async (id, q) => {
        if (q > 0) {
            try {
                const response = await axios.post(`${baseUrl}/order/updatetocart/${cartId}`, {
                    product_id: id,
                    quantity: q,
                });
                // After updating quantity, refresh the cart list
                refreshCartList();
            } catch (error) {
                console.error("Error updating quantity");
                toast.error("Failed to update quantity");
            }
        } else {
            removeItem(id)
        }

    };

    const removeItem = async (id) => {
        try {
            const response = await axios.post(`${baseUrl}/order/removetocart/${userId}`, {
                product_id: id,
            });
            toast.success("Product removed form cart");
            // After updating quantity, refresh the cart list
            refreshCartList();
            // console.log(response.data);
        } catch (error) {
            console.error("Error updating quantity:");
            toast.error("Failed to update quantity");
        }
    };

    const removeLike = async (id) => {
        try {
            const response = await axios.post(`${baseUrl}/review/unlike/${userId}`, {
                product_id: id,
            });
            toast.success("Product removed form like list");
            refreshLikeList();
        } catch (error) {
            console.error("Error:");
            toast.error("Failed to remove from like list");
        }

    };

    return (
        <>
            <div className="cart-list" key={selectedProduct.id}>
                <Row>
                    <Col className="image-holder" sm={4} md={3}>
                        <img src={selectedProduct.product_id.photos[0]} alt="" />
                    </Col>
                    <Col sm={8} md={9}>
                        <Row className="cart-content justify-content-center">
                            <Col xs={12} sm={9} className="cart-details">
                                <h3>{selectedProduct.product_id.product_name}</h3>
                                <h4>
                                    ₹{selectedProduct.product_id.reseller_price}.00 * {selectedProduct.quantity}
                                </h4>
                            </Col>
                            {quantity > 0 ?
                                <Col xs={12} sm={3} className="cartControl">
                                    <button
                                        className="incCart"
                                        onClick={() => updateQuantity(selectedProduct.product_id._id, quantity + 1)}
                                    >
                                        <i className="fa-solid fa-plus"></i>
                                    </button>
                                    <h4 style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                        {quantity}
                                    </h4>
                                    <button
                                        className="desCart"
                                        onClick={() => updateQuantity(selectedProduct.product_id._id, quantity - 1)}
                                    >
                                        <i className="fa-solid fa-minus"></i>
                                    </button>
                                </Col>
                                :
                                <Col xs={12} sm={3} className="cartControl">
                                    <button
                                        aria-label="Add"
                                        type="submit"
                                        className="add"
                                        style={{
                                            width: "200px",
                                            height: '40px',
                                            backgroundColor: '#056174',
                                            color: 'white',
                                            borderRadius: '5px',
                                            fontSize: "17px"
                                        }}
                                        onClick={() => handleAddToCart(selectedProduct.product_id._id)}
                                    >
                                        Add To Cart
                                    </button>
                                </Col>
                            }
                        </Row>
                    </Col>
                    <button
                        className="delete"
                        onClick={() => removeLike(selectedProduct.product_id._id)}
                    >
                        <MdDelete size={"30px"} />
                    </button>
                </Row>
            </div>
        </>
    );
};

export default LikeDetails;
