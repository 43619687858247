import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const baseUrl = `https://ecom-f6hf.onrender.com`;

// Create context
export const CategoryContext = createContext();

// Context provider component
export const CategoryContextProvider = ({ children }) => {
    const navigate = useNavigate();

    const [categoryList, setCetegoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                // Check if ads are already fetched
                if (categoryList.length === 0) {
                    console.log("321313131313");
                    const response = await axios.get(`${baseUrl}/category/categoryshow`);
                    setCetegoryList(response.data.data);
                }
                setIsLoading(false);
            } catch (error) {
                setError(error);
                console.error("Error fetching ads:");
                navigate("/error");
            }
        };
        fetchCategory();
    }, [categoryList, navigate]);

    return (
        <CategoryContext.Provider value={{ categoryList, isLoading, error }}>
            {children}
        </CategoryContext.Provider>
    );
};
