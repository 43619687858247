import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const baseUrl = `https://ecom-f6hf.onrender.com`;

// Create context
export const BranchContext = createContext();

// Context provider component
export const BranchContextProvider = ({ children }) => {
    const navigate = useNavigate();

    const [branchList, setBranchList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBranch = async () => {
            try {
                // Check if ads are already fetched
                if (branchList.length === 0) {
                    console.log("321313131313");
                    const response = await axios.get(`${baseUrl}/branch/showbranch`);
                    setBranchList(response.data.data);
                }
                setIsLoading(false);
            } catch (error) {
                setError(error);
                console.error("Error fetching ads:");
                navigate("/error");
            }
        };
        fetchBranch();
    }, [branchList, navigate]);

    return (
        <BranchContext.Provider value={{branchList, isLoading, error }}>
            {children}
        </BranchContext.Provider>
    );
};
