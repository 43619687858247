const MyOrder = ({ myorder }) => {

    const getStatusStyle = (status) => {
        switch (status) {
          case 'pending':
            return { color: 'blue', paddingLeft: '5px' };
          case 'on going':
          case 'shipping':
            return { color: 'green', paddingLeft: '5px' };
          case 'cancel':
          case 'rejected':
            return { color: 'red', paddingLeft: '5px' };
          case 'past order':
            return { color: 'purple', paddingLeft: '5px' };
          default:
            return { paddingLeft: '5px' }; // default style if status doesn't match
        }
      };

    // Given timestamp string
    const timestampString = myorder.order_date;

    // Function to format the date
    const formatDate = (timestamp) => {
        // Create a new Date object from the timestamp string
        const date = new Date(timestamp);

        // Extract day, month, and year
        const day = date.getDate();
        const month = date.getMonth() + 1; // getMonth() returns 0-based month, so +1
        const year = date.getFullYear();

        // Format day and month to always have two digits (e.g., 01, 02, ..., 09)
        const formattedDay = String(day).padStart(2, '0');
        const formattedMonth = String(month).padStart(2, '0');

        // Return the formatted date in DDMMYYYY format
        return `${formattedDay}/${formattedMonth}/${year}`;
    };

    // Format the given timestamp string
    const formattedDate = formatDate(timestampString);

    return (
        <>
            <div className="card mt-4">
                <div className="card-body p-0 table-responsive">
                    <h4 className="p-3 mb-0">Order Details</h4>
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th scope="col">Description</th>
                                <th scope="col"></th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {myorder.orderitems.map((orderItem) => (
                                <tr>
                                    <th>
                                        <img src={orderItem.product_id.photos[0]} alt="product" className="" width="80" />
                                    </th>
                                    <td>{orderItem.product_id.product_name}</td>
                                    <td>₹{orderItem.orderitem_id.price} X {orderItem.orderitem_id.quantity}</td>
                                    <td><strong>₹{orderItem.orderitem_id.sub_total}</strong></td>
                                </tr>
                            ))}
                            <tr>
                                <th colSpan="2">
                                    <span className="text-muted">Status:
                                    </span>
                                    <strong style={getStatusStyle(myorder.status)}>{myorder.status}</strong>
                                </th>

                                <td>
                                    <span className="text-muted">Order Mode</span>
                                    <strong style={{ paddingLeft: "5px" }}>{myorder.ordermode}</strong>
                                </td>
                                <td>
                                    <span className="text-muted">Discount</span>
                                    <strong style={{ paddingLeft: "5px" }}>{myorder.coupon_per}</strong>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan="2">
                                    <span className="text-muted">Date:
                                    </span>
                                    <strong style={{ paddingLeft: "5px" }}>{formattedDate}</strong>
                                </th>
                                <td>
                                    <span className="text-muted"></span>
                                    <strong></strong>
                                </td>
                                <td>
                                    <span className="text-muted">Total Price</span>
                                    <strong style={{ paddingLeft: "5px" }}>{myorder.total_amount}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default MyOrder;