import React, { useRef, useState, useEffect } from 'react';
import './auth.css'; // Import your CSS file
import { BsInputCursorText } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const baseUrl = `https://ecom-f6hf.onrender.com`;

const SignInOtp = () => {
    const otp = localStorage.getItem('tempPcOTP');
    const mail = localStorage.getItem('tempPcEmail');
    const password = localStorage.getItem('tempPcPassword');
    const mobile = localStorage.getItem('tempPcMobile');
    const name = localStorage.getItem('tempPcName');
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const navigate = useNavigate();

    const [secondsLeft, setSecondsLeft] = useState(40);
    const timeoutRef = useRef(null);

    const [otpinfo, setOtpInfo] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const countdown = () => {
            if (secondsLeft > 0) {
                setSecondsLeft(prevSeconds => prevSeconds - 1);
            } else {
                localStorage.removeItem('tempPcOTP');
                clearTimeout(timeoutRef.current);
            }
        };

        timeoutRef.current = setInterval(countdown, 1000);

        return () => {
            clearInterval(timeoutRef.current);
        };
    }, [secondsLeft]);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();


        try {
            if (otp === otpinfo) {
                setIsOtpVerified(true);
                const response = await axios.post(`${baseUrl}/register`, {
                    email: mail,
                    password: password,
                    name: name,
                    mobile_numer: mobile,
                    profile_photo: "",
                    lat: "",
                    lon: "",
                });

                localStorage.setItem("PcUserId", response.data.data._id);
                localStorage.setItem("PcUserRole", response.data.data.role);
                navigate("/referral");
                localStorage.removeItem('tempPcOTP');
                localStorage.removeItem('tempPcEmail');
                localStorage.removeItem('tempPcPassword');
                localStorage.removeItem('tempPcMobile');
                localStorage.removeItem('tempPcName');
            } else {
                toast.error("OTP not match");
            }
        } catch (error) {
            console.error("Error fetching",);
            navigate("/error");
        } finally {
            setLoading(false); // Stop loading animation
        }
    };

    const handleResendOTP = async () => {
        // Logic to resend OTP
        setSecondsLeft(30);
        toast.info("Resending OTP...");
        const response = await axios.post(`${baseUrl}/verify`, {
            email: mail,
            password: password,
            name: name,
            mobile_numer: mobile,
            profile_photo: "",
            lat: "",
            lon: "",
        });

        localStorage.setItem("tempPcOTP", response.data.otp);
        console.log('Resending OTP...');
        toast.success("Check your register mail for otp");
    };

    return (
        <div className="wrapper">
            <p className="account-signup">
                <h2 className="form-signin-heading">Verify OTP</h2>
            </p>
            <form className="form-signin" onSubmit={handleSubmit}>
                <div className="inner-addon left-addon">
                    <label className="control-label">OTP</label>
                    <i className="glyphicon">
                        <BsInputCursorText />
                    </i>
                    <input
                        type="text"
                        className="form-control"
                        name="otp"
                        placeholder="0000"
                        required
                        autoFocus
                        value={otpinfo}
                        onChange={(e) => setOtpInfo(e.target.value)}
                    />
                </div>
                <button className="btn btn-lg btn-primary btn-block authbutton" type="submit" disabled={isLoading}>
                    {isLoading ? 'Verifying...' : 'Verify'}
                </button>
            </form>
            <p className="account-signup" style={{ color: "white" }}>
                {secondsLeft > 0 ? `Time left: ${secondsLeft}` : (
                    <span onClick={handleResendOTP} style={{ cursor: "pointer", textDecoration: "underline" }}>Resend OTP?</span>
                )}
            </p>
        </div>
    );
};

export default SignInOtp;
