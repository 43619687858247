import React from 'react';

const TermsOfService = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy governs how PC Computer ("we", "our", or "us") collects, uses, maintains, and discloses
        information collected from users ("you" or "User") of the PC Computer website ("Site") and any related services,
        features, or applications.
      </p>
      
      <h2>1. Information We Collect</h2>
      <p>
        We may collect personal identification information from Users in various ways, including:
      </p>
      <ul>
        <li><strong>Registration:</strong> When you register for an account on our Site, we may collect your name, email address, mailing address, and phone number.</li>
        <li><strong>Orders:</strong> When you place an order, we collect information necessary to process your order, including payment information.</li>
        <li><strong>Newsletter Subscription:</strong> If you subscribe to our newsletter, we collect your email address to send you promotional and informational content.</li>
        <li><strong>Surveys and Forms:</strong> If you participate in surveys or fill out forms on our Site, we may collect the information provided.</li>
        <li><strong>Customer Support:</strong> When you contact our customer support team, we may collect information necessary to assist you with your inquiry or issue.</li>
      </ul>
      
      <h2>2. How We Use Your Information</h2>
      <p>
        We may use the information we collect from you for the following purposes:
      </p>
      <ul>
        <li><strong>To Personalize User Experience:</strong> We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.</li>
        <li><strong>To Improve Our Site:</strong> We continually strive to improve our website offerings based on the information and feedback we receive from you.</li>
        <li><strong>To Process Transactions:</strong> We use the information Users provide when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.</li>
        <li><strong>To Send Periodic Emails:</strong> The email address Users provide for order processing will only be used to send them information and updates pertaining to their order. It may also be used to respond to their inquiries and/or other requests or questions.</li>
      </ul>
      
      <h2>3. How We Protect Your Information</h2>
      <p>
        We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Site.
      </p>
      
      <h2>4. Sharing Your Personal Information</h2>
      <p>
        We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.
      </p>
      
      <h2>5. Third-Party Websites</h2>
      <p>
        Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors, and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site.
      </p>
      
      <h2>6. Compliance with Children's Online Privacy Protection Act</h2>
      <p>
        Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information on our Site from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.
      </p>
      
      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        PC Computer has the discretion to update this Privacy Policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of modifications.
      </p>
      
      <h2>8. Your Acceptance of These Terms</h2>
      <p>
        By using this Site, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
      </p>
      
      <h2>9. Contacting Us</h2>
      <p>
        If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:
        <br />
        PC Computer
        <br />
        [Your Company Address]
        <br />
        [City, State, Zip Code]
        <br />
        [Email Address]
        <br />
        [Phone Number]
      </p>
    </div>
  );
};

export default TermsOfService;
