import React, { useState } from 'react';
import '../auth.css'; // Import your CSS file
import { useNavigate } from 'react-router-dom';
import { MdLock } from 'react-icons/md';
import { toast } from 'react-toastify';
import axios from 'axios';

const baseUrl = `https://ecom-f6hf.onrender.com`;

const ForgotNewPass = () => {
    const navigate = useNavigate();
    const tempId = localStorage.getItem('tempPcUserId');

    const [newPass, setNewPass] = useState('');
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        // Validate password length
        if (newPass.length < 6) {
            toast.error("Password must be at least 6 characters long.");
            return;
        }

        setLoading(true); // Start loading animation

        try {
            const response = await axios.post(`${baseUrl}/forgetpass/${tempId}`, {
                password: newPass,
            });
            navigate("/login");
            toast.success("Password updated successfully.");
        } catch (error) {
            console.error("Error fetching:");
            toast.error("Failed to update password. Please try again later.");
        } finally {
            setLoading(false); // Stop loading animation
        }
    };

    return (
        <div className="wrapper">
            <p className="account-signup">
                <h2 className="form-signin-heading">New Password</h2>
            </p>
            <form className="form-signin" onSubmit={handleSubmit}>
                <div className="inner-addon left-addon">
                    <label className="control-label">Enter new password</label>
                    <i className="glyphicon">
                        <MdLock />
                    </i>
                    <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="*******"
                        required
                        autoFocus
                        value={newPass}
                        onChange={(e) => setNewPass(e.target.value)}
                    />
                </div>
                <button className="btn btn-lg btn-primary btn-block authbutton" type="submit" disabled={isLoading}>
                    {isLoading ? 'In Process...' : 'Submit'}
                </button>
            </form>
        </div>
    );
};

export default ForgotNewPass;
