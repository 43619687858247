import React, { useState } from 'react';
import '../auth.css'; // Import your CSS file
import { useNavigate } from 'react-router-dom';
import { MdEmail } from 'react-icons/md';
import { toast } from 'react-toastify';
import axios from 'axios';

const baseUrl = `https://ecom-f6hf.onrender.com`;

const ForgotPass = () => {
    const navigate = useNavigate();

    const [emailid, setEmailId] = useState('');
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        setLoading(true); // Start loading animation

        try {
            const response = await axios.post(`${baseUrl}/finduser`, {
                email: emailid,
            });
            // console.log(response);
            if (response.data.status === "done") {
                localStorage.setItem("tempPcOTP", response.data.otp);
                localStorage.setItem("tempPcUserId", response.data.id);
                localStorage.setItem("tempPcEmail", emailid);
                navigate("/forgototp");
                toast.success("Check your registered email for OTP.");
            } else {
                toast.error("User not found.");
            }

        } catch (error) {
            console.error("Error fetching:");
            toast.error("Failed to fetch user data. Please try again later.");
        } finally {
            setLoading(false); // Stop loading animation
        }
    };

    return (
        <div className="wrapper">
            <p className="account-signup">
                <h2 className="form-signin-heading">Forgot Password</h2>
            </p>
            <form className="form-signin" onSubmit={handleSubmit}>
                <div className="inner-addon left-addon">
                    <label className="control-label">Enter registered email id</label>
                    <i className="glyphicon">
                        <MdEmail />
                    </i>
                    <input
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="abc@gmail.com"
                        required
                        autoFocus
                        value={emailid}
                        onChange={(e) => setEmailId(e.target.value)}
                    />
                </div>
                <button className="btn btn-lg btn-primary btn-block authbutton" type="submit" disabled={isLoading}>
                    {isLoading ? 'In Process...' : 'Verify'}
                </button>
            </form>
        </div>
    );
};

export default ForgotPass;
