import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const baseUrl = `https://ecom-f6hf.onrender.com`;

// Create context
export const addressContext = createContext();

// Context provider component
export const AddressContextProvider = ({ children }) => {
    const userId = localStorage.getItem('PcUserId');

    const navigate = useNavigate();

    const [addressData, setAddressData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchAddressData = async () => {
        if (userId !== null) {
            try {
                const response = await axios.get(`${baseUrl}/address/findaddress/${userId}`);
                setAddressData(response.data.data);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                console.error("Error fetching ads:");
                navigate("/error");
            }
        }
    };

    const refreshAddress = async () => {
        setIsLoading(true);
        await fetchAddressData();
    };

    useEffect(() => {
        if (userId !== null) {
            fetchAddressData();
        }
    }, [userId]);

    return (
        <addressContext.Provider value={{ addressData, isLoading, error, refreshAddress }}>
            {children}
        </addressContext.Provider>
    );
};
