import './error.css'

const Error = () => {
    return (
        <>
            <div class="section errorpage">
                <h1 class="error">404</h1>
                <div class="page">Ooops!!! The page you are looking for is not found</div>
                <a class="back-home" href="/">Back to home</a>
            </div>
        </>
    );
}

export default Error