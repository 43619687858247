import React, { useContext, useState } from 'react';
import "./profile.css";
import { profileContext } from "../context/profileContext";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap'; // Import necessary components from react-bootstrap
import { addressContext } from '../context/addressContext';
import { myOrderContext } from '../context/myOrderContext';
import axios from 'axios';
import MyOrder from '../components/myOrder/myorder';

const baseUrl = `https://ecom-f6hf.onrender.com`;

const Profile = () => {
    const navigate = useNavigate();
    const [profileDetails, setProfileDetails] = useState('profile');
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [changeAddressModal, setShowAddressModal] = useState(false); // State to control modal visibility
    const [selectAddress, setSelectAddress] = useState('');

    const [resellerEmail, setResellerEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, SetState] = useState('');
    const [pincode, SetPincode] = useState('');


    const { addressData, refreshAddress } = useContext(addressContext);
    const { myOrderData } = useContext(myOrderContext);
    const { profileData } = useContext(profileContext);

    const login = localStorage.getItem('PcUserRole');
    const userId = localStorage.getItem('PcUserId');

    const handleProfileChange = (option) => {
        setProfileDetails(option);
    };

    const handleLogout = () => {
        // Remove PcUserRole and PcUserId from localStorage
        localStorage.removeItem('PcUserRole');
        localStorage.removeItem('PcUserId');
        toast.success("Successfully logged out");
        navigate("/");
    };

    const handleRequest = () => {
        setShowModal(true); // Show modal when becoming a reseller is requested
    };

    const handleCloseModal = () => {
        setShowModal(false); // Close modal when canceling becoming a reseller
    };

    const showAddAddressModal = (type) => {
        setSelectAddress(type);
        if (type === "permanent") {
            if (addressData.length > 0) {
                setAddress(addressData[0].address);
                setCity(addressData[0].city);
                SetState(addressData[0].state);
                SetPincode(addressData[0].pincode)
            }
        } else {
            if (addressData.length > 1) {
                setAddress(addressData[1].address);
                setCity(addressData[1].city);
                SetState(addressData[1].state);
                SetPincode(addressData[1].pincode)
            }
        }
        setShowAddressModal(true);
    };


    const closeAddAddressModal = () => {
        setShowAddressModal(false);
    };

    const handleBecomeReseller = async () => {
        setShowModal(false);
        try {
            await axios.post(`${baseUrl}/sendreq`, {
                "user_id": userId,
                "gst_no": resellerEmail,
                "city": addressData.length > 0 ? addressData[0].city : "",
                "pin_code": addressData.length > 0 ? addressData[0].pincode : ""
            });
            toast.success("Sucessfully request sended");
        } catch (error) {
            console.error("Error");
            toast.error("Something wrong, please try again");
        }
    };

    const handleAddressApiSelection = async () => {
        if (selectAddress === "permanent" && addressData.length === 0) {
            handleAddAddress()
        } else if (selectAddress === "permanent" && addressData.length > 0) {
            handleChangeAddress()
        }

        if (selectAddress === "temporary" && addressData.length === 1) {
            handleAddAddress()
        } else if (selectAddress === "temporary" && addressData.length > 1) {
            handleChangeAddress()
        }
    };

    const handleChangeAddress = async () => {
        console.log("change");
        setShowAddressModal(false);
        if (selectAddress === "permanent") {
            var id = addressData[0]._id;
        } else {
            var id = addressData[1]._id;
        }
        try {
            await axios.post(`${baseUrl}/address/updateaddress/${id}`, {
                address: address,
                state: state,
                city: city,
                pincode: pincode,
                country: "India",
                type: selectAddress
            });
            toast.success("sucessfully changed");
            refreshAddress()
        } catch (error) {
            console.error("Error");
            toast.error("Something wrong, please try again");
        }
    };


    const handleAddAddress = async () => {
        console.log("add");
        setShowAddressModal(false);
        try {
            await axios.post(`${baseUrl}/address/addaddress/${userId}`, {
                address: address,
                state: state,
                city: city,
                pincode: pincode,
                country: "India",
                type: selectAddress
            });
            toast.success("Sucessfully Added");
            refreshAddress()
        } catch (error) {
            console.error("Error");
            toast.error("Something wrong, please try again");
        }
    };

    return (
        <div>
            <section className="my-5">
                <div className="container">
                    <div className="main-body">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-column align-items-center text-center">
                                            <img
                                                src={profileData.profile_photo}
                                                alt="Admin"
                                                className="rounded-circle p-1 bg-warning"
                                                width={110}
                                            />
                                            <div className="mt-3">
                                                <h4>{profileData.name}</h4>
                                                <p className="text-secondary mb-1">{profileData.mobile_number}</p>
                                                <p className="text-muted font-size-sm">{profileData.email}</p>
                                            </div>
                                        </div>
                                        <div className="list-group list-group-flush text-center mt-4">
                                            <a
                                                href="#"
                                                className={`list-group-item list-group-item-action border-0 ${profileDetails === 'profile' ? 'active' : ''}`}
                                                onClick={() => handleProfileChange('profile')}
                                            >
                                                Profile Information
                                            </a>
                                            <a
                                                href="#"
                                                className={`list-group-item list-group-item-action border-0 ${profileDetails === 'order' ? 'active' : ''}`}
                                                onClick={() => handleProfileChange('order')}
                                            >
                                                Orders
                                            </a>
                                            <a
                                                href="#"
                                                className={`list-group-item list-group-item-action border-0 ${profileDetails === 'address' ? 'active' : ''}`}
                                                onClick={() => handleProfileChange('address')}
                                            >
                                                Address Book
                                            </a>
                                            <a href="#" className="list-group-item list-group-item-action border-0" onClick={() => handleLogout()}>
                                                Logout
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                {profileDetails === 'profile' && (
                                    <div id="profileDetails" className="card">
                                        <div className="card-body">
                                            <div className="profile-info">
                                                <h5>Profile Information</h5>
                                                <p><strong>Name:</strong> {profileData.name}</p>
                                                <p><strong>Email Address:</strong> {profileData.email}</p>
                                                <p><strong>Contact:</strong> {profileData.mobile_number}</p>
                                                <p><strong>Role:</strong> {profileData.role}</p>
                                                {profileData.gst_no === "" ?
                                                    <div>
                                                        <a href="#" className="list-group-item list-group-item-action border-0 active list-group text-center" onClick={() => handleRequest()}>
                                                            Become a Reseller
                                                        </a>
                                                    </div>
                                                    : <p><strong>GST No:</strong> {profileData.gst_no}</p>}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {profileDetails === 'order' && (
                                    <div id="orderDetails" className="order_card">
                                        <p>*for cancel order call us</p>
                                        {myOrderData.map((order) => (
                                            <MyOrder
                                                myorder={order}
                                            />
                                        )).reverse()}
                                    </div>
                                )}
                                {profileDetails === 'address' && (
                                    <div>
                                        <div id="addressBook" className="card">
                                            <div className="card-body">
                                                <h5>Permanent Address</h5>
                                                {addressData.length > 0 ?
                                                    <div>
                                                        <p><strong>Address:</strong> {addressData[0].address}</p>
                                                        <p><strong>City:</strong> {addressData[0].city}</p>
                                                        <p><strong>State:</strong> {addressData[0].state}</p>
                                                        <p><strong>Pincode:</strong> {addressData[0].pincode}</p>
                                                    </div>
                                                    : <button className="add_address_button" onClick={() => showAddAddressModal('permanent')}>Add Address</button>
                                                }
                                                {addressData.length > 0 &&
                                                    <div>
                                                        <a href="#" className="list-group-item list-group-item-action border-0 active list-group text-center" onClick={() => showAddAddressModal('permanent')}>
                                                            Change
                                                        </a>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {addressData.length > 0 &&
                                            <div style={{ paddingTop: "10px" }}>
                                                <div id="addressBook" className="card">
                                                    <div className="card-body">
                                                        <h5>Temporary Address</h5>
                                                        {addressData.length > 1 ?
                                                            <div>
                                                                <p><strong>Address:</strong> {addressData[1].address}</p>
                                                                <p><strong>City:</strong> {addressData[1].city}</p>
                                                                <p><strong>State:</strong> {addressData[1].state}</p>
                                                                <p><strong>Pincode:</strong> {addressData[1].pincode}</p>
                                                            </div>
                                                            : <button className="add_address_button" onClick={() => showAddAddressModal('temporary')}>Add Address</button>
                                                        }
                                                        {addressData.length > 1 &&
                                                            <div>
                                                                <a href="#" className="list-group-item list-group-item-action border-0 active list-group text-center" onClick={() => showAddAddressModal('temporary')}>
                                                                    Change
                                                                </a>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                )}
                                {/* Modal for becoming a reseller */}
                                <Modal show={showModal} onHide={handleCloseModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Become a Reseller</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group controlId="resellerEmail">
                                            <Form.Label>Enter GST No</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your gst number"
                                                value={resellerEmail}
                                                onChange={(e) => setResellerEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                        <h5 style={{ paddingTop: "10px" }}>Instruction</h5>
                                        <p>
                                            • After pressing submit button it will take few business days to verify details.
                                        </p>
                                        <p>
                                            • You can visit offline store for better deals and more convinant communication.
                                        </p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseModal}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={handleBecomeReseller}>
                                            Become a Reseller
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={changeAddressModal} onHide={closeAddAddressModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Address</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group controlId="Enter Address">
                                            <Form.Control
                                                type="textarea"
                                                placeholder="Enter your address"
                                                value={address}
                                                autoFocus
                                                onChange={(e) => setAddress(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="Enter city" style={{ paddingTop: "10px" }}>
                                            <Form.Control
                                                type="textarea"
                                                placeholder="Enter your city"
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="Enter state" style={{ paddingTop: "10px" }}>
                                            <Form.Control
                                                type="textarea"
                                                placeholder="Enter your state"
                                                value={state}
                                                onChange={(e) => SetState(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="Enter pincode" style={{ paddingTop: "10px" }}>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter your pincode"
                                                value={pincode}
                                                onChange={(e) => SetPincode(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={closeAddAddressModal}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={handleAddressApiSelection}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {/* End of Modal */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Profile;
