import { useContext } from "react";
import { BranchContext } from "../context/branchContext";
import { Col, Container, Row } from "react-bootstrap";

const Branch = () => {
    const { branchList } = useContext(BranchContext);

    return (
        <Container style={{ paddingTop: "20px" }}>
            {/* <Col md={3} sm={5} xs={10} className="product mtop"> */}
                <div>
                    <Row className="justify-content-center product mtop" md={3} sm={5} xs={10}>
                        {branchList.map((branch) => (
                            <div style={{ paddingBottom: "20px" }}>
                                <div id="addressBook" className="card">
                                    <div className="card-body">
                                        <div>
                                            <p><strong style={{color: "red", fontSize: "30px"}}>{branch.branch_name}</strong></p>
                                            <p><strong>Branch Manager:</strong> {branch.branch_manager}</p>
                                            <p><strong>Branch Mobile:</strong> {branch.mobile_num}</p>
                                            <p><strong>Branch Email:</strong> {branch.email}</p>
                                            <p><strong>Branch Address:</strong> {branch.address}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Row>
                </div>
            {/* </Col> */}
        </Container>
    );
}

export default Branch;